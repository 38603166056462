import Circle from "../assets/icons/circle.svg";
import LinkedIn from "../assets/icons/linkedin.svg";
import GitHub from "../assets/icons/github.svg";
import ProfilePicture from "../assets/images/profile.png";

export default function Profile() {
  // RETURNS THE PROFILE SECTION HTML
  return (
    <section className="profile container flex">
      <div className="profileContent flex">
        <div>
          <h1 className="textWhite fsXXL ffBebas uppercase lineHeightS">
            Hi, I am Pëllumb Sadiku.
          </h1>
          <p className="lineHeightM">
            A motivated individual with in-depth knowledge of languages and
            development tools, seeking a position in a growth-oriented company
            where I can use my skills to the advantage of the company while
            having the scope to develop my own skills.
          </p>
        </div>
        <div className="profileButtons flex">
          <a
            href="#contact"
            className="greenButton transition flex textBlack bgGreen fsS bold uppercase"
          >
            Contact Me <img src={Circle} />
          </a>
          <a
            href="https://www.linkedin.com/in/pellumbsadiku/"
            target="_blank"
            className="grayButton transition flex bgDarkGray"
          >
            <img src={LinkedIn} />
          </a>
          <a
            href="https://github.com/pellumbsadiku"
            target="_blank"
            className="grayButton transition flex bgDarkGray"
          >
            <img src={GitHub} />
          </a>
        </div>
      </div>
      <div className="profilePicture">
        <img src={ProfilePicture} />
      </div>
    </section>
  );
}
