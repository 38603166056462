import ProfilePicture from "../assets/images/profile.png";
import Circle from "../assets/icons/circle.svg";
import LinkedIn from "../assets/icons/linkedin.svg";
import GitHub from "../assets/icons/github.svg";
import Resume from "../assets/resume.pdf";
import { NavLink } from "react-router-dom";

export default function About(props) {
  // RETURN THE ABOUT SECTION HTML
  return (
    <section
      // CONDITIONALLY CHECKING IF WE NEED TO ADD A BORDER OR NOT
      className={
        "about container flex " + (props.border === true ? "border" : "")
      }
    >
      <div className="aboutContent flex">
        <div>
          <h2
            className={
              "sectionTitle ffBebas textWhite lineHeightS " +
              (props.isSinglePage ? "fsXXL" : "fsXL")
            }
          >
            About me
          </h2>
        </div>
        <div
          className={
            "aboutDescription flex " +
            (props.isSinglePage ? "singlePageAbout" : "")
          }
        >
          <h3 className="textWhite fsL medium lineHeightM">
            I am a software developer based in Vushtrri, Kosovo. Has Computer
            Engineering background.
          </h3>
          <p className="lineHeightM">
            I also share my passion and knowledge for web development as a
            Software Developer Instructor at Innovation Academy, where I design
            and deliver engaging and interactive courses on Python, JavaScript,
            and SQL. I enjoy helping students learn and master the skills and
            concepts that are essential for becoming proficient full stack
            engineers in the StarLabs industry.
          </p>
          {/* CONDITIONALLY CHECKING IF WE NEED TO ADD MORE BUTTON OR CV BUTTON */}
          {props.more ? (
            <div className="sources flex">
              <NavLink
                to="/about"
                className="sourceLink tranition pointer textGreen fsXS bold lineHeightM uppercase flex"
              >
                More about me
              </NavLink>
            </div>
          ) : (
            <div className="profileButtons flex">
              <a
                href={Resume}
                className="greenButton transition flex textBlack bgGreen fsS bold uppercase"
              >
                Resume <img src={Circle} />
              </a>
              <a
                href="https://www.linkedin.com/in/pellumbsadiku/"
                target="_blank"
                className="grayButton transition flex bgDarkGray"
              >
                <img src={LinkedIn} />
              </a>
              <a
                href="https://github.com/pellumbsadiku"
                target="_blank"
                className="grayButton transition flex bgDarkGray"
              >
                <img src={GitHub} />
              </a>
            </div>
          )}
        </div>
      </div>
      <div className="aboutImage flex">
        <img src={ProfilePicture} />
      </div>
    </section>
  );
}
