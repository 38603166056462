import arrowIcon from "../assets/icons/arrow.svg";
import gitHubIcon from "../assets/icons/github.svg";

export default function ProjectCard(props) {
  // CONDITIONALLY CHECKS WHEATHER IF WE CAN SHARE THE GITHUB REPO CODE OR NOT
  let projectSources =
    props.githubLink !== undefined ? (
      <div className="sources flex textGreen">
        <a
          className="sourceLink transition pointer textGreen fsXS bold lineHeightM uppercase flex"
          href={props.demoLink}
          target="_blank"
        >
          Live Demo <img src={arrowIcon} />
        </a>
        <a
          className="sourceLink transition pointer textGreen fsXS bold lineHeightM uppercase flex"
          href={props.githubLink}
          target="_blank"
        >
          See on GitHub <img src={gitHubIcon} />
        </a>
      </div>
    ) : (
      <div className="sources textGreen">
        <a
          className="sourceLink transition pointer textGreen fsXS bold lineHeightM uppercase flex"
          href={props.demoLink}
          target="_blank"
        >
          Live Demo <img src={arrowIcon} />
        </a>
      </div>
    );

  // RETURNS THE PROJECT CARD TEMPLATE
  return (
    <div className="projectCard flex">
      <a
        href={props.demoLink}
        target="_blank"
        className="projectImage bgDarkGray flex"
      >
        <img src={props.image} />
      </a>
      <div className="projectContent flex">
        <h3 className="textWhite fsL medium lineHeightM">{props.title}</h3>
        <p className="lineHeightM">{props.description}</p>
        <div className="projectInfo">
          <ul className="fsS medium lineHeightM">
            <li className="uppercase textWhite flex">Project Info</li>
            <li className="flex ">
              <span className="textWhite">Year</span>
              {props.year}
            </li>
            <li className="flex ">
              <span className="textWhite">Role</span>
              {props.role}
            </li>
          </ul>
        </div>
        {projectSources}
      </div>
    </div>
  );
}
