import Header from "../components/Header.js";
import Profile from "../components/Profile.js";
import Projects from "../components/Projects.js";
import About from "../components/About.js";
import Connect from "../components/Connect.js";

function Home() {
  // HTML PAGE
  return (
    <div>
      <Header main="Work" />
      <Profile />
      <Projects />
      <About border={true} more={true} />
      <Connect />
    </div>
  );
}

export default Home;
