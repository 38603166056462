import React, { useState } from "react";
import Menu from "../assets/icons/menu.svg";
import MenuClose from "../assets/icons/menuClose.svg";

import { NavLink } from "react-router-dom";

export default function Header(props) {
  // OPEN AND CLOSE NAV, ALSO CHANGES THE TOGGLE FROM OPEN TO CLOSE
  const [isOpen, setIsOpen] = useState(false);

  function toggleeNav() {
    setIsOpen(!isOpen);
  }

  // RETURNS THE HEADER, INCLUDING NAV BAR
  return (
    <header className="header container lineHeightM flex textCream">
      <div>
        <NavLink to="/" className="fsL ffBebas uppercase letterSpacingS">
          Pëllumb Sadiku
        </NavLink>
      </div>
      <nav>
        <img
          className="navToggle transition pointer"
          src={isOpen ? MenuClose : Menu}
          onClick={toggleeNav}
        />
        <ul
          className={`nav transition flex ffInter letterSpacingM + ${
            isOpen === true ? "" : "hidden"
          }`}
        >
          <li className="transition">
            {/* CONDITIONALLY CHECKING WHETHER WE NEED TO GO TO WORK OR HOME PAGE */}
            {props.main === "Home" ? (
              <NavLink to="/" onClick={toggleeNav}>
                {props.main}
              </NavLink>
            ) : (
              <a href="#Work" onClick={toggleeNav}>
                {props.main}
              </a>
            )}
          </li>
          <li className="transition">
            <NavLink to="/about" onClick={toggleeNav}>
              About
            </NavLink>
          </li>
          <li className="transition">
            <a href="#contact" onClick={toggleeNav}>
              Contact
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
}
