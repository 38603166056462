export default function ExperienceCard(props) {
  // RETURNS THE TEMPLATE FOR THE EXPERIENCE CARD WHICH TAKES: job, jobStart, jobEnd, company, description AS PROPS
  return (
    <div className="experienceCard flex">
      <div className="experiencesMain flex">
        <h3 className="fsM textWhite lineHeightM letterSpacingS">
          {props.job}
        </h3>
        <h4 className="experienceDate lineHeightM">
          {props.jobStart} - {props.jobEnd}
        </h4>
      </div>
      <h3 className="experienceCompany textGreen medium lineHeightM">
        {props.company}
      </h3>
      <p className="lineHeightM">{props.description}</p>
    </div>
  );
}
