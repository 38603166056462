import ExperienceCard from "./ExperienceCard";

export default function Experience() {
  // RETURNS THE HTML FOR THE EXPERIENCE SECTION
  return (
    <section className="experience flex container">
      <h2 className="sectionTitle ffBebas textWhite fsXL">My Experience</h2>
      <div className="experiencesHolder flex">
        <ExperienceCard
          job="Software Engineer"
          jobStart="May 2023"
          jobEnd="Present"
          company="Ritech International AG"
          description="Crafting Recruiting Software with Symfony, React, and Angular Technologies."
        />
        <ExperienceCard
          job="Software Developer Instructor"
          jobStart="Jan 2022"
          jobEnd="Present"
          company="Innovation Academy"
          description="Teaching individuals or groups how to design, develop, and implement software applications."
        />
        <ExperienceCard
          job="Full Stack Developer"
          jobStart="Oct 2022"
          jobEnd="May 2023"
          company="Black Bird Marketing"
          description="Developing Website applications using MERN stack ( MongoDB, Express.js, React.js, Node.js )."
        />
        <ExperienceCard
          job="Full Stack Developer"
          jobStart="Oct 2020"
          jobEnd="Oct 2022"
          company="StarLabs"
          description="Developing Websites using PHP, Wordpress. Implementing, designing, creating Wordpress sites with
          parent and child themes. "
        />
        <ExperienceCard
          job="Freelancer - Web Developer"
          jobStart="Feb 2019"
          jobEnd="Jul 2020"
          company="Programming Club - UMIB"
          description="Developing Websites using PHP, jQuery, Wordpress, Woocommerce."
        />
        <ExperienceCard
          job="Tech Support"
          jobStart="Feb 2017"
          jobEnd="Aug 2018"
          company="Arding SHPK"
          description="Support ARDING and IPKO Telecomm. Technical teams on daily administrative work."
        />
      </div>
    </section>
  );
}
