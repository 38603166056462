import ProjectCard from "./ProjectCard";
import Kadrimmo from "../assets/images/kadrimmo.png";
import Milingona from "../assets/images/milingona.png";
import KidsMilingona from "../assets/images/kidsMilingona.png";
import RimaGroup from "../assets/images/rimaGroup.png";
import Arding from "../assets/images/arding.png";

export default function Projects() {
  // RETUNRNS THE PROJECTS SECTION HTML
  return (
    <section className="projects container" id="Work">
      <div className="projectsContent">
        <div className="projectIntro">
          <h2 className="sectionTitle ffBebas textWhite fsXL">
            Featured Projects
          </h2>
          <p className="lineHeightM">
            Here are some of the selected projects that showcase my passion as a
            Software Engineer.
          </p>
        </div>
        <div className="projectsHolder flex">
          <ProjectCard
            image={Kadrimmo}
            title="Kadrimmo"
            description="Website for a building company, 3D planning and real estate located in Switzerland."
            demoLink="https://kadrimmo.ch/"
            year={2023}
            role="Full-Stack Developer"
          />
          <ProjectCard
            image={Milingona}
            title="Milingona"
            description="Website for a school, online tutorials, blogs, news and a personal shop. "
            demoLink="https://milingona.al/"
            year={2023}
            role="Full-Stack Developer"
          />
          <ProjectCard
            image={KidsMilingona}
            title="Kids Milingona"
            description="Website for a school for kids aged 6 to 12, courses, news and book store."
            demoLink="https://kids.milingona.al/"
            year={2023}
            role="Full-Stack Developer"
          />
          <ProjectCard
            image={RimaGroup}
            title="Rima Group"
            description="Website for a company that offers comprehensive services in the field of railway control and safety technology as well as fiber optic cabling."
            demoLink="https://www.rima-group.de/en/home/"
            year={2023}
            role="Full-Stack Developer"
          />
          <ProjectCard
            image={Arding}
            title="Arding Al"
            description="Website for a company that offers services in the fields of electrical engineering, high voltage and fiber optic technology."
            demoLink="https://arding-al.com/"
            year={2023}
            role="Full-Stack Developer"
          />
        </div>
      </div>
    </section>
  );
}
