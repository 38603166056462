import Header from "../components/Header.js";
import About from "../components/About.js";
import Capabilities from "../components/Capabilities.js";
import Experience from "../components/Experience.js";
import Connect from "../components/Connect.js";

function Home() {
  // ABOUT PAGE
  return (
    <div>
      <Header main="Home" />
      <About border={false} isSinglePage={true} />
      <Capabilities />
      <Experience />
      <Connect />
    </div>
  );
}

export default Home;
