import CapabilitiesButton from "./CapabilitiesButton.js";

export default function Capabilities() {
  // RETURNS THE CAPABILITIES SECTION HTML
  return (
    <section className="capabilities flex container">
      <h2 className="sectionTitle textWhite ffBebas fsXL">My Capabilities</h2>
      <div className="capabilitiesContent">
        <p className="lineHeightM">
          I am always looking to add more skills, especially in the realm of
          coding. The ever-evolving nature of technology demands a continuous
          commitment to learning and staying abreast of the latest developments.
        </p>
        <div className="buttonsHolder flex">
          <CapabilitiesButton name="HTML" />
          <CapabilitiesButton name="CSS" />
          <CapabilitiesButton name="JavaScript" />
          <CapabilitiesButton name="jQuery" />
          <CapabilitiesButton name="React" />
          <CapabilitiesButton name="Node.js" />
          <CapabilitiesButton name="Express.js" />
          <CapabilitiesButton name="Laravel" />
          <CapabilitiesButton name="Database" />
          <CapabilitiesButton name="Mongo DB" />
          <CapabilitiesButton name="C#" />
          <CapabilitiesButton name="JSON" />
          <CapabilitiesButton name="SQL" />
          <CapabilitiesButton name="mySQL" />
          <CapabilitiesButton name="Rest APIs" />
          <CapabilitiesButton name="PHP" />
          <CapabilitiesButton name="SASS" />
          <CapabilitiesButton name="Bootstrap" />
          <CapabilitiesButton name="Tailwind" />
          <CapabilitiesButton name="Project Managment" />
          <CapabilitiesButton name="Python" />
          <CapabilitiesButton name="Angular" />
          <CapabilitiesButton name="Adobe Photoshop" />
        </div>
      </div>
    </section>
  );
}
