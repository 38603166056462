import LinkedIn from "../assets/icons/linkedin.svg";
import GitHub from "../assets/icons/github.svg";
import YouTube from "../assets/icons/youtube.svg";
import Instagram from "../assets/icons/instagram.svg";
import Close from "../assets/icons/close.svg";
import Resume from "../assets/resume.pdf";

// Currently SERVICE ID, TEMPLATE ID and PUBLIC KEY is at ardbrahaa@gmail.com account on https://www.emailjs.com/
// To get personal SERVICE ID, TEMPLATE ID and PUBLIC KEY go to https://www.emailjs.com/, create an account and finish the remaining parts
// Tutorial: https://youtu.be/I4DKr1JLC50?si=r96hRvBKwxxbHTcW
// Emails are sent at: pellumb.sadiku@gmail.com

// Import neccessary files to send an Email
import { useRef } from "react";
import emailjs from "@emailjs/browser";

export default function Connect() {
  // FUNCTION FOR SENDING THE EMAIL
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_0u9nmgk", // SERVICE ID
        "template_0ccu75k", // TEMPLATE ID
        form.current,
        "IZTjyaTyhfFZ5Ov5l" // PUBLIC KEY
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  // RETURNS THE HTML FOR THE CONNECT SECTION
  return (
    <section className="connect flex container" id="contact">
      <div className="connectContent flex">
        <div>
          <h2 className="sectionTitle textWhite ffBebas fsXL">Let's connect</h2>
          <p className="lineHeightM">
            Say hello at{" "}
            <a
              className="greenUnderline transition"
              href="mailto:pellumb.sadiku@gmail.com"
            >
              pellumb.sadiku@gmail.com
            </a>
          </p>
          <p className="lineHeightM">
            For more info here's my{" "}
            <a className="greenUnderline transition" href={Resume}>
              resume
            </a>
          </p>
          <div className="connectSources flex">
            <a
              href="https://www.linkedin.com/in/pellumbsadiku/"
              target="_blank"
            >
              <img src={LinkedIn} />
            </a>
            <a href="https://github.com/pellumbsadiku" target="_blank">
              <img src={GitHub} />
            </a>
            <a href="https://www.instagram.com/pellumbsadiku/" target="_blank">
              <img src={Instagram} />
            </a>
            <a
              href="https://www.youtube.com/@pellumbsadiku4059"
              target="_blank"
            >
              <img src={YouTube} />
            </a>
          </div>
        </div>
        <p className="copyright fsS lineHeightM hidden">
          &copy; 2024 Pëllumb Sadiku
        </p>
      </div>

      <form
        className="connectForm flex"
        ref={form}
        onSubmit={(e) => {
          sendEmail(e);
          document.querySelector(".successModal").classList.remove("hidden");
        }}
      >
        <label htmlFor="name" className="fsS lineHeightM">
          Name
        </label>
        <input
          type="text"
          id="name"
          className="bgDarkGray textCream"
          name="userName"
          required
        />
        <label htmlFor="email" className="fsS lineHeightM">
          Email
        </label>
        <input
          type="email"
          id="email"
          className="bgDarkGray textCream"
          name="userEmail"
          required
        />
        <label htmlFor="subject" className="fsS lineHeightM">
          Subject
        </label>
        <input
          type="text"
          id="subject"
          className="bgDarkGray textCream"
          name="userSubject"
          required
        />
        <label htmlFor="message" className="fsS lineHeightM">
          Message
        </label>
        <textarea
          id="message"
          className="bgDarkGray textCream"
          name="userMessage"
          required
        ></textarea>
        <div>
          <input
            type="submit"
            className="submitButton  pointer transition fsS bold uppercase bgGreen textBlack"
          />
        </div>
        <div>
          <p className="copyright fsS lineHeightM">
            &copy; 2024 Pëllumb Sadiku
          </p>
        </div>
        <div className="successModal flex textBlack bold fsM transition hidden">
          <p>
            Email sent successfully!{" "}
            <img
              className="closeModal pointer"
              src={Close}
              onClick={() =>
                document.querySelector(".successModal").classList.add("hidden")
              }
            />
          </p>
        </div>
      </form>
    </section>
  );
}
